// extracted by mini-css-extract-plugin
export var containerFluid = "component-module--container-fluid--f9360";
export var contentHeader = "component-module--content-header--4669a";
export var contentHeaderCms = "component-module--content-header-cms--f5a57";
export var contentHeaderDate = "component-module--content-header-date--3153f";
export var contentHeaderText = "component-module--content-header-text--878e4";
export var news = "component-module--news--376ec";
export var newsAbstract = "component-module--news-abstract--12f41";
export var newsContent = "component-module--news-content--c9881";
export var newsDate = "component-module--news-date--4f877";
export var newsImage = "component-module--news-image--6803a";
export var newsImageContainer = "component-module--news-image-container--91d6f";
export var newsImageText = "component-module--news-image-text--76573";
export var newsImageTextFade = "component-module--news-image-text-fade--f2c6b";
export var newsImageTextSubtitle = "component-module--news-image-text-subtitle--a5fd0";
export var newsImageTextTitle = "component-module--news-image-text-title--67089";
export var newsItem = "component-module--news-item--8f4c4";
export var newsLink = "component-module--news-link--ac9c0";
export var newsSingleContent = "component-module--news-single-content--3f7ce";