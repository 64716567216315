import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../../lib/translate';

import CmsImageComponent from '../../content-elements/image';

import * as newsList from './component.module.scss';

const CmsNewsList = ({
  items,
}) => (
  <div className={newsList.news}>
    {items.map(({
      id,
      image,
      date,
      title,
      fullUrlPath,
    }) => (
      <div key={id} className={newsList.newsItem}>
        <div className={`${newsList.newsImageContainer}`}>
          {image && (
            <CmsImageComponent image={image} />
          )}
          <div className={`${newsList.newsImageText} ${newsList.newsImageTextFade}`}>
            <span className={newsList.newsDate}>{date}</span>
          </div>
        </div>

        <h3 className={newsList.newsImageTextTitle}>{title}</h3>

        <Link className={`${newsList.newsLink} btn btn-primary`} to={fullUrlPath}>
          {t`news_list_item_details_button_label`}
        </Link>
      </div>
    ))}
  </div>
);

CmsNewsList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CmsNewsList;
