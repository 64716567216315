import React from 'react';
import {graphql} from 'gatsby';
import PropTypes from 'prop-types';

import t from '../lib/translate';

import CmsNewsList from '../components/single-news-list';
import Dropdown from '../component-elements/dopdown';
import Helmet from '../components/helmet';
import Paginations from '../component-elements/pagination';
import Layout from '../layouts/default';
import Breadcrumb from '../component-elements/breadcrumb';
import Header from '../component-elements/header';

import Section from '../component-elements/content-section';

const NewsListTemplate = ({data, pageContext}) => {
	const {
		currentPage,
		numPagesNews,
		lengthOfPostsCategory,
		catfilter: categoryFilter,
		url
	} = pageContext;

	const {
		allNews,
		allDatoCmsNews: {
			nodes: news
		},
		filterPosts: {
			nodes: filteredNews
		},
		datoCmsPage: page,
		allDatoCmsNewsCategory: {
			nodes: categories
		}
	} = data;

	const {
		name,
		url: pageUrl,
		seoMetaTags: helmetProps,
		seo,
		metaRobots,
		metaCanonical,
		header: pageHeaders
	} = page;

	const numberOfPages = numPagesNews || lengthOfPostsCategory;

	return (
			<Layout textcolorHeader="white" textcolorHeaderMobile="white">
				<Breadcrumb
						items={[
							{id: name, name}
						]}
				/>

				{pageHeaders?.length > 0 && (
						<Header data={pageHeaders[0]} />
				)}

				<Section background="Weiß">
					<div className="container-fluid">
						<Dropdown
								data={categories}
								active={categoryFilter}
								pageUrl={pageUrl}
								allNews={allNews}
						/>

						{news && (
								<CmsNewsList
										items={filteredNews?.length > 0 ? filteredNews : news}
								/>
						)}
					</div>

					{news && (
							<Paginations data={numberOfPages} activePage={currentPage} />
					)}

					<Helmet
							tags={helmetProps}
							metaRobots={metaRobots}
							metaCanonical={metaCanonical}
							title={seo?.title}
							titleFallback={t('cms_page_title_fallback', name)}
							url={url}
					/>
				</Section>
			</Layout>
	);
};

NewsListTemplate.defaultProps = {
	pageContext: {
		currentPage: 1
	}
};

NewsListTemplate.propTypes = {
	data: PropTypes.objectOf(PropTypes.any).isRequired,
	pageContext: PropTypes.objectOf(PropTypes.any)
};

export default NewsListTemplate;

export const query = graphql`
fragment newsListItem on DatoCmsNews {
    id
    title
    fullUrlPath
    date(formatString: "DD.MM.YYYY")
    categories { name }
    image { ...genericTeaserImage }
    seoMetaTags { tags }
}

query newsListQuery($id: String, $lang: String, $catfilter: String, $skip: Int = 0, $limit: Int) {
    datoCmsPage(id: {eq: $id}, locale: {eq: $lang}) {
        ...SeoMetaTags
        ...header
        metaCanonical
        metaRobots
    }

    allNews: allDatoCmsNews(filter: {locale: {eq: $lang}}) {
        totalCount
        nodes {
            categories{
                name
            }
        }
    }

    allDatoCmsNews(
        skip: $skip,
        limit: $limit 
        sort: {
            order: DESC, fields: date
        }
        filter: {
            locale: { eq: $lang}
        }
    ) {
        totalCount
        nodes {
            ...newsListItem
        }
    }

    filterPosts: allDatoCmsNews(
        skip: $skip
        limit: $limit
        sort: {order: DESC, fields: date}
        filter: {
            categories: {
                elemMatch: {name: {eq: $catfilter}}
            }
            locale: {eq: $lang}
        }
    ) {
        totalCount
        nodes {
            ...newsListItem
        }
    }

    allDatoCmsNewsCategory(filter: {locale: {eq: $lang}}) {
        nodes {
            name
        }
    }
}
`;
