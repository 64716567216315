import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import t from '../../lib/translate';

import {
  newsFilter,
  newsFilterItem,
  newsFilterOption,
  newsFilterOptionActive,
} from './component.module.scss';

const Dropdown = ({
  data: dopdownList,
  active,
  pageUrl,
  allNews,
}) => (
  <div className={`${newsFilter} row`}>
    <div className={`${newsFilterItem}`}>
      <Link key="clear" className={`${newsFilterOption} ${!active ? `${newsFilterOptionActive}` : ''} btn btn-secondary btn-blank `} to={`/${pageUrl}/`}>
        <span key="key" className="news-filter-option-name">{t`news_list_filter_all_button_label`}</span>
        <span className="news-filter-option-count">{allNews.totalCount}</span>
      </Link>
    </div>
    {
      dopdownList.map((singleList) => (
        <div key={singleList.name} className={`${newsFilterItem}`}>
          <Link
            className={`${newsFilterOption} ${active === singleList.name ? `${newsFilterOptionActive}` : ''}  btn btn-secondary btn-blank `}
            to={`/${pageUrl}/c/${singleList.name.toLowerCase()}/`}
          >
            <span key={singleList.name} className="news-filter-option-name">{singleList.name}</span>
            <span className="news-filter-option-count">{allNews.nodes.filter((p) => p.categories.find((c) => c.name === singleList.name)).length}</span>
          </Link>
        </div>
      ))
    }
  </div>
);

Dropdown.defaultProps = {
  active: '',
};

Dropdown.propTypes = {
  data: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
  allNews: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.objectOf(PropTypes.array))).isRequired,
    totalCount: PropTypes.number,
  }).isRequired,
  active: PropTypes.string,
  pageUrl: PropTypes.string.isRequired,
};

export default Dropdown;
